import React from "react";
import LabelledSelect from "js/components/labelled-select";
import {fetchEtlConfig} from "js/data/etl-configs/api";
import * as Immutable from "immutable";
import {getErrorMessage} from "js/utils/errors";
import NumberField from "js/components/number-field";
import {TextField, Checkbox, RaisedButton, Tabs, Tab, Snackbar, IconButton} from "material-ui";
import {DatePicker} from "rc-datepicker";
import BatchBulkTable from "js/app-areas/customer-support/batch-bulk-table";
import * as fetch from "js/fetch";
import {Filter} from "js/components/data-table";
import moment from "moment-timezone";
import * as BulkSchedule from "js/data/bulk-schedule";
import ClientScheduleInfo from "js/components/bulk-schedule-info";

const DISPLAY_FORMAT = "YYYY-MM-DD";
const SERVER_FORMAT = "YYYY-MM-DD 00:00:00";

const BulkForm = React.memo(({
  clientId, onRequestReload, client, crmType, existingBulkJobs, loadAndSetExistingBulkJobs
}) => {
  const displayBatchTab = crmType === "bullhornrest";
  const [activeTab, setActiveTab] = React.useState(displayBatchTab ? "BATCH" : "JSON");
  const [entityOptions, setEntityOptions] = React.useState(null);
  const [entities, setEntities] = React.useState(Immutable.List());
  const [submitting, setSubmitting] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [jsonConfig, setJsonConfig] = React.useState(Immutable.Map({comment: ""}));
  const [bulkSchedule, setBulkSchedule] = React.useState(null);
  const [lastRefreshTimestamp, setLastRefreshTimestamp] = React.useState(null);

  const handleRefresh = React.useCallback(() => {
    loadAndSetExistingBulkJobs(clientId);
    setLastRefreshTimestamp(moment().format("YY-MM-DD HH:mm:ss"));
  }, [loadAndSetExistingBulkJobs, clientId]);

  const [batchConfig, setBatchConfig] = React.useState(
      Immutable.fromJS({
        selectedEntities: Immutable.Set(),
        daysToBatchInto: 30,
        comment: ""
      })
  );

  const loadEntities = (cube19ClientId) => {
    return fetchEtlConfig(cube19ClientId).then(
        typeToConfig => {
          return typeToConfig
              .getIn(["merged", "entities"])
              .filter(entity => !entity.get("mapOnly") && !entity.get("deltaOnly"));
        },
        error => {
          getErrorMessage(error).then(message => setErrorMessage(message));
          return Immutable.List();
        });
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      handleRefresh();
    }, 60000);
    return () => clearInterval(interval);
  }, [clientId, handleRefresh]);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      displayBatchTab ? loadEntities(clientId).then(entities => {
        const options = entities
            .map(entity => {
              const isReference = entity.get("properties").get("isReference");
              const label = isReference ? entity.get("name") + " - Reference" : entity.get("name");
              return Immutable.Map({label: label, value: entity.get("name")})})
            .sortBy(entity => entity.get("label"));
        setEntityOptions(options);
        setEntities(entities);
      }) : Promise.resolve(),
      BulkSchedule.load(clientId)
          .then(schedule => {
            setBulkSchedule(schedule);
          }, error => {
            getErrorMessage(error).then(message => setErrorMessage(message));
          })])
        .then(() => {
          setLoading(false);
        });
  }, [clientId, displayBatchTab]);

  const getTabStyle = (value) => {
    const isSelected = activeTab === value;
    return {
      backgroundColor: isSelected ? "#EEEEDD" : "#DFDFD2",
      color: isSelected ? "black" : "rgba(51, 51, 51, 0.5)",
      textTransform: "none",
      border: "1px solid #888888"
    };
  };

  const commentPrefix = `New bulk job for ${client.get("internal_name")} via UI: `;

  const createBatchedBulkJob = (clientId, config) =>
      fetch.post("etl2-batched-bulk-job", {
        "client-id": clientId,
        "load-date-range-for-reference-entities": config.get("useDatesForRefEntities"),
        "batch-length-days": config.get("daysToBatchInto"),
        "created-after": config.get("createdAfter") && moment(config.get("createdAfter"), DISPLAY_FORMAT)
            .format(SERVER_FORMAT),
        "created-before": config.get("createdBefore") && moment(config.get("createdBefore"), DISPLAY_FORMAT)
            .format(SERVER_FORMAT),
        "modified-after": config.get("modifiedAfter") && moment(config.get("modifiedAfter"), DISPLAY_FORMAT)
            .format(SERVER_FORMAT),
        "modified-before": config.get("modifiedBefore") && moment(config.get("modifiedBefore"), DISPLAY_FORMAT)
            .format(SERVER_FORMAT),
        entities: config.get("selectedEntities"),
        message: commentPrefix + config.get("comment"),
        "ignore-client-schedule": config.get("ignoreSchedule")
      });

  const createBulkJob = (clientId, config) =>
      fetch.post("etl2-bulk-job", {
        "client-id": clientId,
        metadata: config.get("metadata"),
        "start-after-minutes": parseInt(config.get("startAfterMinutes")),
        "ignore-client-schedule": config.get("ignoreSchedule"),
        message: commentPrefix + config.get("comment")
      });

  const handleBatchedBulkSubmit = (config) => {
    setSubmitting(true);
    createBatchedBulkJob(clientId, config)
        .then(
            () => {
              setSubmitting(false);
              onRequestReload();
              setBatchConfig(Immutable.Map({comment: "", selectedEntities: Immutable.List(), daysToBatchInto: 30}));
            },
            error => {
              error.response
                  .json()
                  .then(response => {
                    setErrorMessage(response.message);
                    setSubmitting(false);
                  });
            });
  };

  const handleJSONSubmit = (config) => {
    setSubmitting(true);
    createBulkJob(clientId, config)
        .then(
            () => {
              setSubmitting(false);
              onRequestReload();
              setJsonConfig(Immutable.Map({comment: "", metadata: "", startAfterMinutes: 0}));
            },
            error => {
              error.response
                  .json()
                  .then(response => {
                    setErrorMessage(response.message);
                    setSubmitting(false);
                  });
            });
  };

  const cloneBatch = (data) => {
    const batchConfig = Immutable.fromJS(JSON.parse(data.get("metadata")));
    setBatchConfig(Immutable.fromJS({
      daysToBatchInto: batchConfig.get("batch-length-days"),
      selectedEntities: batchConfig.get("entities"),
      createdAfter: batchConfig.get("created-after") && moment(batchConfig.get("created-after"), SERVER_FORMAT)
          .format(DISPLAY_FORMAT),
      createdBefore: batchConfig.get("created-before") && moment(batchConfig.get("created-before"), SERVER_FORMAT)
          .format(DISPLAY_FORMAT),
      modifiedAfter: batchConfig.get("modified-after") && moment(batchConfig.get("modified-after"), SERVER_FORMAT)
          .format(DISPLAY_FORMAT),
      modifiedBefore: batchConfig.get("modified-before") && moment(batchConfig.get("modified-before"), SERVER_FORMAT)
          .format(DISPLAY_FORMAT),
      useDatesForRefEntities: batchConfig.get("load-date-range-for-reference-entities"),
      ignoreSchedule: batchConfig.get("ignore-client-schedule"),
      comment: ""
    }));
    var scrollingElement = (document.scrollingElement || document.body);
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
    setActiveTab("BATCH");
  };

  const cloneJob = (data) => {
    setJsonConfig(Immutable.fromJS({
      metadata: data.get("metadata"),
      ignoreSchedule: data.get("ignore-client-schedule"),
      comment: ""
    }));
    var scrollingElement = (document.scrollingElement || document.body);
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
    setActiveTab("JSON");
  };

  const rescheduleBatch = (data, filterToFailed) => {
    setSubmitting(true);

    const jobs = data.get("entity") ? data.get("children") : data
        .get("children")
        .valueSeq()
        .toList()
        .flatMap(entityType => entityType.flatMap(type => type.get("children")));

    const jobsToReschedule = filterToFailed ? jobs
        .filter(job => job.get("status") === "FAILED") : jobs;

    const idsToReschedule = jobsToReschedule.map(job => job.get("id"));

    rescheduleBulkJobs(clientId, idsToReschedule)
        .then(_ => {
          onRequestReload();
          setSubmitting(false);
        });
  };

  const cancelRemaining = (data) => {

    const jobs = data.get("entity") ? data.get("children") : data
        .get("children")
        .valueSeq()
        .toList()
        .flatMap(entityType => entityType.flatMap(type => type.get("children")));

    const outstandingJobIds = jobs
        .filter(job => !job.get("finished-timestamp"))
        .map(job => job.get("id"));

    handleCancelJobs(outstandingJobIds);
  };

  const handleCancelJobs = (jobIds) => {
    cancelBulkJobs(clientId, jobIds).then(_ => {
      onRequestReload();
      setSubmitting(false);
    });
  };

  const actionToFunction = Immutable.Map({
    CLONE_BATCH: data => cloneBatch(data),
    CLONE_JOB: data => cloneJob(data),
    RESCHEDULE_FAILED: data => rescheduleBatch(data, true),
    RESCHEDULE_ALL: data => rescheduleBatch(data, false),
    CANCEL_REMAINING: data => cancelRemaining(data),
    CANCEL_JOB: data => handleCancelJobs(Immutable.List([data.get("id")]))
  });

  const errorMessageContent = <div>
    <i style={{marginRight: 10}} className="fa fa-warning" />{"Error: " + errorMessage}
  </div>;

  const [filterText, setFilterText] = React.useState("");

  const handleFilterChange = e => {
    setFilterText(e.target.value);
  };

  return <div>
    <div style={{display: "flex", justifyContent: "space-between", margin: "20px 20px 0 20px"}}>
      <div>
        <RaisedButton
            label={<i className="fa fa-refresh" />}
            style={{height: 30, minWidth: 30, padding: 0, marginRight: 10}}
            onClick={handleRefresh} />
        {lastRefreshTimestamp && "Last refresh: " + lastRefreshTimestamp}
      </div>
      <Filter value={filterText} onChange={handleFilterChange} />
    </div>
    <div style={{padding: "5px 10px 20px 10px", overflowX: "scroll"}}>
      <BatchBulkTable
          filterText={filterText}
          entities={entities}
          actionToFunction={actionToFunction}
          existingBulkJobs={existingBulkJobs} />
    </div>
    <div>
      <div style={{borderBottom: "1px solid #888888"}}>
        <Tabs value={activeTab} onChange={value => setActiveTab(value)} style={{width: "50%"}}>
          {displayBatchTab && <Tab
              id="BATCH"
              style={getTabStyle("BATCH")}
              label="Batched Bulk Job"
              value="BATCH" />}
          <Tab
              id="JSON"
              style={getTabStyle("JSON")}
              label="JSON Bulk Job"
              value="JSON" />
        </Tabs>
      </div>
      {activeTab === "BATCH" && <BatchBulkForm
          entityOptions={entityOptions}
          handleSubmit={handleBatchedBulkSubmit}
          batchConfig={batchConfig}
          commentPrefix={commentPrefix}
          setBatchConfig={setBatchConfig}
          client={client}
          bulkSchedule={bulkSchedule}
          submitting={submitting}
          loading={loading} />}
      {activeTab === "JSON" && <JSONBulkForm
          handleSubmit={handleJSONSubmit}
          commentPrefix={commentPrefix}
          client={client}
          bulkSchedule={bulkSchedule}
          jsonConfig={jsonConfig}
          setJsonConfig={setJsonConfig}
          submitting={submitting}
          loading={loading} />}
    </div>
    <Snackbar
        open={!!errorMessage}
        onRequestClose={() => setErrorMessage(null)}
        style={{backgroundColor: "red", left: "20%"}}
        message={errorMessageContent}
        autoHideDuration={10000}
    />
  </div>;
});

const BatchBulkForm = React.memo(({
  entityOptions,
  submitting,
  loading,
  client,
  bulkSchedule,
  commentPrefix,
  handleSubmit,
  batchConfig,
  setBatchConfig
}) => {

  const cancelForm = () => {
    setBatchConfig(Immutable.Map({comment: "", selectedEntities: Immutable.List(), daysToBatchInto: 30}));
  };
  const createdBeforeIsValid = !batchConfig.get("createdBefore") || moment(
      batchConfig.get("createdBefore"),
      DISPLAY_FORMAT,
      true).isValid();
  const createdAfterIsValid = !batchConfig.get("createdAfter") || moment(
      batchConfig.get("createdAfter"),
      DISPLAY_FORMAT,
      true).isValid();
  const modifiedBeforeIsValid = !batchConfig.get("modifiedBefore") || moment(
      batchConfig.get("modifiedBefore"),
      DISPLAY_FORMAT,
      true).isValid();
  const modifiedAfterIsValid = !batchConfig.get("modifiedAfter") || moment(
      batchConfig.get("modifiedAfter"),
      DISPLAY_FORMAT,
      true).isValid();

  const disableSubmit = submitting || loading || !batchConfig.get("comment") || !createdBeforeIsValid ||
      !createdAfterIsValid || !modifiedBeforeIsValid || !modifiedAfterIsValid;

  return <div style={{margin: 25}}>
    <div style={{display: "flex", padding: 0}}>
      <div style={{flexGrow: 2, marginRight: 30}}>
        {entityOptions && <LabelledSelect
            label="Entities to Bulk"
            selectedValues={batchConfig.get("selectedEntities")}
            closeMenuOnSelect={false}
            onChange={entities => setBatchConfig(batchConfig.set("selectedEntities", entities))}
            options={entityOptions} />}
      </div>
      <div style={{display: "flex", flexDirection: "column"}}>
        Days to Batch Into
        <NumberField
            id="days-to-batch-into"
            range={[1]}
            value={batchConfig.get("daysToBatchInto")}
            onChange={num => setBatchConfig(batchConfig.set("daysToBatchInto", num))} />
      </div>
    </div>
    <div style={{display: "flex", marginTop: 10}}>
      <div style={{display: "flex", flexDirection: "column", flexGrow: 2}}>
        <div style={{display: "flex"}}>
          <div style={{width: "100%", marginRight: 15}}>
            Created After
            <ClearableDatePicker
                value={batchConfig.get("createdAfter")}
                onChange={(newValue) => {
                  if (newValue) {
                    setBatchConfig(batchConfig.set("createdAfter", newValue));
                  } else {
                    setBatchConfig(batchConfig.delete("createdAfter"));
                  }
                }} />
          </div>
          <div style={{width: "100%", marginRight: 15}}>
            Created Before
            <ClearableDatePicker
                value={batchConfig.get("createdBefore")}
                onChange={(newValue) => {
                  if (newValue) {
                    setBatchConfig(batchConfig.set("createdBefore", newValue));
                  } else {
                    setBatchConfig(batchConfig.delete("createdBefore"));
                  }
                }} />
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div style={{width: "100%", marginRight: 15}}>
            Modified After
            <ClearableDatePicker
                value={batchConfig.get("modifiedAfter")}
                onChange={(newValue) => {
                  if (newValue) {
                    setBatchConfig(batchConfig.set("modifiedAfter", newValue));
                  } else {
                    setBatchConfig(batchConfig.delete("modifiedAfter"));
                  }
                }} />
          </div>
          <div style={{width: "100%", marginRight: 15}}>
            Modified Before
            <ClearableDatePicker
                value={batchConfig.get("modifiedBefore")}
                onChange={(newValue) => {
                  if (newValue) {
                    setBatchConfig(batchConfig.set("modifiedBefore", newValue));
                  } else {
                    setBatchConfig(batchConfig.delete("modifiedBefore"));
                  }
                }} />
          </div>
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "column", width: 270, marginTop: 20, paddingLeft: 20}}>
        <Checkbox
            checked={batchConfig.get("useDatesForRefEntities") || false}
            onCheck={e => setBatchConfig(batchConfig.set("useDatesForRefEntities", e.target.checked))}
            style={{fontSize: 15, padding: 10}}
            labelStyle={{lineHeight: 1}}
            label="Use date range for reference entities" />
        <Checkbox
            checked={batchConfig.get("ignoreSchedule") || false}
            style={{fontSize: 15, padding: 10}}
            labelStyle={{lineHeight: 1}}
            onCheck={e => setBatchConfig(batchConfig.set("ignoreSchedule", e.target.checked))}
            label="Ignore client schedule" />
        {!batchConfig.get("ignoreSchedule") && <ClientScheduleInfo bulkSchedule={bulkSchedule} loading={loading}/>}
      </div>
    </div>
    <div style={{display: "flex", flexDirection: "column", flexGrow: 2, marginTop: 15}}>
      Comment
      <TextField
          id="comment"
          errorText={batchConfig.get("comment").length === 0 && "A reason for this change is required"}
          style={{width: "100%"}}
          value={commentPrefix + batchConfig.get("comment")}
          onChange={e => setBatchConfig(batchConfig.set("comment", e.target.value.substring(commentPrefix.length)))} />
    </div>
    <div style={{display: "flex", marginTop: 15, justifyContent: "flex-end"}}>
      <RaisedButton onClick={cancelForm}>Cancel</RaisedButton>
      <RaisedButton
          disabled={disableSubmit}
          onClick={() => handleSubmit(batchConfig)}
          buttonStyle={{padding: "0 8px"}}
          style={{padding: "0 5px", marginLeft: 5}}>
        Start New Bulk Job
      </RaisedButton>
    </div>
  </div>;
});

const JSONBulkForm = React.memo(({
  handleSubmit,
  submitting,
  loading,
  client,
  bulkSchedule,
  commentPrefix,
  jsonConfig,
  setJsonConfig
}) => {
  const isMetadataValid = isValidJson(jsonConfig.get("metadata"));
  const isMissingComment = !jsonConfig.get("comment") || jsonConfig.get("comment").length === 0;

  const cancelForm = () => {
    setJsonConfig(Immutable.Map({comment: "", metadata: "", startAfterMinutes: 0}));
  };

  return <div>
    <div style={{display: "flex"}}>
      <TextField
          id="metadata"
          floatingLabelText="Metadata"
          value={jsonConfig.get("metadata")}
          style={{margin: 15}}
          multiLine={true}
          fullWidth={true}
          onChange={e => setJsonConfig(jsonConfig.set("metadata", e.target.value))}
          errorText={!isMetadataValid && "The metadata is not valid JSON"} />
      <div>
        <TextField
            id="start-after-minutes"
            value={jsonConfig.get("startAfterMinutes") || 0}
            style={{margin: 15, maxWidth: 200}}
            type="number"
            floatingLabelText="Minutes To Wait"
            onChange={e => setJsonConfig(jsonConfig.set("startAfterMinutes", e.target.value))} />
        <Checkbox
            checked={jsonConfig.get("ignoreSchedule") || false}
            style={{fontSize: 14, padding: 10, marginLeft: 4}}
            labelStyle={{lineHeight: 1, marginTop: 4}}
            onCheck={e => setJsonConfig(jsonConfig.set("ignoreSchedule", e.target.checked))}
            label="Ignore client schedule" />
        {!jsonConfig.get("ignoreSchedule") && <div style={{width: 200, marginLeft: 15}}>
          <ClientScheduleInfo bulkSchedule={bulkSchedule} loading={loading}/>
        </div>}
      </div>
    </div>
    <TextField
        id="comment"
        floatingLabelText="Comment"
        style={{margin: 15, width: "95%"}}
        value={commentPrefix + jsonConfig.get("comment")}
        multiLine={true}
        fullWidth={true}
        onChange={e => setJsonConfig(jsonConfig.set("comment", e.target.value.substring(commentPrefix.length)))}
        errorText={isMissingComment && "A reason for this change is required"} />
    <div style={{display: "flex", margin: 15, width: "100%", justifyContent: "space-between"}}>
      <div style={{display: "flex", marginRight: 30, marginBottom: 25}}>
        <RaisedButton onClick={cancelForm}>Cancel</RaisedButton>
        <RaisedButton
            disabled={submitting || loading || !jsonConfig.get("comment") || !isMetadataValid}
            onClick={() => handleSubmit(jsonConfig)}
            buttonStyle={{padding: "0 8px"}}
            style={{padding: "0 5px", marginLeft: 5}}>
          Start New Bulk Job
        </RaisedButton>
      </div>
    </div>
  </div>;
});

const rescheduleBulkJobs = (clientId, jobIds) =>
    fetch.post("etl2-reschedule-bulk-jobs", {
      "client-id": clientId,
      "job-ids": jobIds
    });

const cancelBulkJobs = (clientId, jobIds) =>
    fetch.post("etl2-cancel-bulk-jobs", {
      "client-id": clientId,
      "job-ids": jobIds
    });

const ClearableDatePicker = ({value, onChange}) => {
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const momentValue = moment(value, DISPLAY_FORMAT, true);

  const clickOutsideRef = React.useRef();
  const handleClickOutside = e => {
    if (!clickOutsideRef.current.contains(e.target)) {
      setShowDatePicker(false);
    }
  };
  React.useEffect(() => {
    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [showDatePicker]);

  return <div style={{display: "flex"}}>
    <TextField
        id={"custom-date-picker"}
        style={{maxWidth: 100, marginBottom: "1.5rem"}}
        value={value || ""}
        onChange={e => onChange(e.target.value)}
        errorText={value && !momentValue.isValid() && `Valid date format: ${DISPLAY_FORMAT}`}
    />
    <div style={{display: "flex", flexDirection: "column"}} ref={clickOutsideRef}>
      <IconButton
          iconClassName="fa fa-calendar"
          onClick={() => setShowDatePicker(!showDatePicker)} />
      {showDatePicker && <div style={{display: "inline-block"}}>
        <DatePicker
            floating={true}
            value={momentValue.isValid() ? momentValue : undefined}
            onChange={jsDate => {
              setShowDatePicker(false);
              onChange(moment(jsDate).format(DISPLAY_FORMAT));
            }} />
      </div>}
    </div>
  </div>;
};

const isValidJson = str => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export default BulkForm;