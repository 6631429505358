import Immutable from "immutable";
import {indexBy} from "js/utils/collections";

const crmInstanceSupportOptions = Immutable.fromJS([
  {
    value: "DEFAULT",
    label: "Default"
  }, {
    value: "LEADS",
    label: "Leads"
  }, {
    value: "OPPORTUNITIES",
    label: "Opportunities"
  }]);

const integerTypeAttributes = Immutable.fromJS([
  {
    name: "normalise",
    label: "Divide by",
    type: "integer"
  }, {
    name: "multiplyBy",
    label: "Multiply by",
    type: "integer"
  }, {
    name: "clampMin",
    label: "Clamp min",
    type: "integer"
  }, {
    name: "clampMax",
    label: "Clamp max",
    type: "integer"
  }]);

const floatTypeAttributes = Immutable.fromJS([
  {
    name: "normalise",
    label: "Divide by",
    type: "float"
  }, {
    name: "multiplyBy",
    label: "Multiply by",
    type: "float"
  }, {
    name: "clampMin",
    label: "Clamp min",
    type: "float"
  }, {
    name: "clampMax",
    label: "Clamp max",
    type: "float"
  }]);

const typeOptions = Immutable.fromJS([
  {
    label: "Non-null String",
    value: "string",
    props: []
  }, {
    label: "Decimal",
    value: "number",
    props: floatTypeAttributes
  }, {
    label: "Integer",
    value: "int",
    props: integerTypeAttributes
  }, {
    label: "Numeric ID",
    value: "numeric-id",
    props: []
  }, {
    label: "DateTime",
    value: "datetime",
    props: []
  }, {
    label: "Unix Timestamp",
    value: "unix-timestamp",
    props: []
  }, {
    label: "Salesforce ID",
    value: "sf-id",
    props: []
  }, {
    label: "UUID",
    value: "uuid",
    props: []
  }, {
    label: "Base62",
    value: "base62",
    props: []
  }, {
    label: "Boolean",
    value: "bool",
    props: []
  }, {
    label: "Json Field",
    value: "json",
    props: []
  }]);

const takeOptions = Immutable.fromJS([
  {
    label: "First",
    value: "FIRST"
  }, {
    label: "Last",
    value: "LAST"
  }, {
    label: "Min",
    value: "MIN"
  }, {
    label: "Max",
    value: "MAX"
  }]);

const typeNameToType = indexBy(t => t.get("value"), typeOptions);

const fieldMappingAttributes = Immutable.fromJS([
  {
    name: "name",
    label: "Name",
    type: "prompt"
  }, {
    name: "fallback",
    label: "Fallback"
  }, {
    name: "mapTo",
    label: "Map to"
  }, {
    name: "translateTo",
    label: "Translate to",
    section: "Translating setup"
  }, {
    name: "defaultTranslation",
    label: "Default transl",
    section: "Translating setup"
  }, {
    name: "nullTranslation",
    label: "Null transl",
    section: "Translating setup"
  }, {
    name: "whitespaceTranslation",
    label: "Whitespace transl",
    section: "Translating setup"
  }, {
    name: "clearDefaultTranslation",
    label: "Clear default transl?",
    type: "boolean",
    section: "Translating setup"
  }, {
    name: "clearTranslations",
    label: "Clear transl?",
    type: "boolean",
    section: "Translating setup"
  }, {
    name: "extendTranslations",
    label: "Extend transl?",
    type: "boolean",
    section: "Translating setup"
  }, {
    name: "extractOnly",
    label: "Extract only?",
    type: "boolean"
  }, {
    name: "mapOnly",
    label: "Map only?",
    type: "boolean"
  }, {
    name: "isJsonField",
    label: "Comes from JSON field?",
    type: "boolean"
  },
  {
    name: "stripHtml",
    label: "Strip HTML?",
    type: "boolean"
  },
  {
    name: "translations",
    label: "Translations",
    type: "json-data",
    section: "Translations"
  }, {
    name: "rejections",
    label: "Rejections",
    type: "json-data",
    jsonShape: "flat-array",
    section: "Rejections"
  }, {
    name: "requiresCrmInstanceSupport",
    label: "Requires CRM support",
    type: "enum",
    options: crmInstanceSupportOptions,
    multi: true,
    section: "Other"
  }, {
    name: "type",
    label: "Type",
    type: "enum",
    options: typeOptions,
    section: "Type"
  }, {
    name: "take",
    label: "Take",
    type: "enum",
    options: takeOptions
  }, {
    name: "linkCrmEntityName",
    label: "CRM entity for ID",
    type: "runtime-list"
  }]);

const commonEntityAttributes = Immutable.fromJS([
  {
    label: "Name",
    name: "name",
    type: "prompt"
  }, {
    label: "Original CRM entity",
    name: "originalCrmEntityName"
  }, {
    label: "ID field",
    name: "crmIdField"
  }, {
    label: "c19 entity",
    name: "cube19EntityToMapTo"
  }, {
    label: "Extract only?",
    name: "extractOnly",
    type: "boolean"
  }, {
    label: "Map only?",
    name: "mapOnly",
    type: "boolean"
  }, {
    label: "Delta only?",
    name: "deltaOnly",
    type: "boolean"
  }, {
    label: "Requires CRM support",
    name: "requiresCrmInstanceSupport",
    type: "enum",
    multi: true,
    options: crmInstanceSupportOptions
  }]);

const commonEntityProps = Immutable.fromJS([
  {
    name: "additionalDataConfig",
    label: "Additional data",
    type: "json-data"
  }, {
    name: "customProcessing",
    label: "Custom processing",
    type: "json-data"
  }, {
    name: "subMappings",
    label: "Sub-Mappings",
    type: "json-data"
  }, {
    name: "isReference",
    label: "Is reference?",
    type: "boolean"
  }, {
    name: "assigneeField",
    label: "Assignee Field"
  }]);

const crmTypeToEntityProps = Immutable.fromJS({
  push_api: [
    {
      name: "discriminatorColumn",
      label: "Discriminator column",
      type: "long-text"
    }, {
      name: "discriminatorMapping",
      label: "Discriminator mapping",
      type: "long-text"
    }, {
      name: "uriRegex",
      label: "URI Regex",
      type: "long-text"
    }],
  bullhornrest: [
    {
      name: "dtoClass",
      label: "DTO java class",
      type: "long-text"
    }, {
      name: "customWhere",
      label: "Custom bullhorn WHERE clause",
      type: "long-text"
    }, {
      name: "entityUpdates",
      label: "Entity update config",
      type: "json-data"
    }]
});

const glSegmentOptions = {
  name: "segmentMethod",
  label: "Method to use to retrieve GL Segments for this entity",
  type: "enum",
  options: [
    {label: "Take from the charge", value: "CHARGE_ROW"},
    {label: "Take from the placement", value: "PLACEMENT"},
    {label: "Take from the earn code", value: "EARN_CODE"},
    {label: "Take from the earn code, fallback to charge, then placement", value: "FALLBACK_EARN_CODE_CHARGE_PLACEMENT"},
    {label: "Take from the charge, fallback to placement", value: "FALLBACK_CHARGE_PLACEMENT"},
    {label: "Take from the placement, fallback to charge", value: "FALLBACK_PLACEMENT_CHARGE"},
    {label: "Take from a placement custom object", value: "PLACEMENT_CUSTOM_OBJECT"},
    {label: "None", value: "NONE"}]
};

const crmTypeAndEntityNameToProps = Immutable.fromJS({
  bullhornrest_AppointmentInterview: [
    {
      name: "requireCandidateAndContactOrJob",
      label: "Require candidate and contact or job",
      type: "boolean"
    }],
  bullhornrest_AppointmentMeeting: [
    {
      name: "requireCandidateOrContact",
      label: "Require candidate or contact",
      type: "boolean"
    }],
  bullhornrest_Candidate: [
    {
      name: "customNameTemplate",
      label: "Custom name template",
      deprecated: "Use formatted fields instead (custom processing)"
    }, {
      name: "customNameFields",
      label: "Custom name fields",
      type: "json-data",
      jsonShape: "flat-array",
      deprecated: "Use formatted fields instead (custom processing)"
    }],
  bullhornrest_Placement: [
    {
      name: "allowNullCurrency",
      label: "Allow null currency",
      type: "boolean"
    }, {
      name: "inferEndDate",
      label: "Infer end date",
      type: "boolean"
    }, {
      name: "legacyPlacementStatuses",
      label: "Legacy placement statuses",
      type: "json-data",
      jsonShape: "flat-array"
    }, {
      name: "customApprovedStatuses",
      label: "Custom approved statuses",
      type: "json-data",
      jsonShape: "flat-array"
    }, {
      name: "maintainEffectiveDateAfterMod",
      label: "Maintain effective date after mod",
      type: "boolean"
    }, {
      name: "splitFieldTuples",
      label: "Split field tuples",
      type: "json-data"
    }, {
      name: "invisibleFilters",
      label: "Invisible filters"
    }],
  talentrover_TR1__Closing_Report__c: [
    {
      name: "splitFieldTuples",
      label: "Split field tuples",
      type: "json-data"
    }],
  bullhornrest_PROCESSOR_billable_charge_split: [glSegmentOptions],
  bullhornrest_PROCESSOR_payable_charge_split: [glSegmentOptions],
  bullhornrest_PROCESSOR_bill_transaction_split: [glSegmentOptions],
  bullhornrest_PROCESSOR_pay_transaction_split: [glSegmentOptions],
  bullhornrest_PayableCharge: [
    {
      name: "burden_percent",
      label: "Burden Percentage",
      type: "float"
    }, {
      name: "reg_burdenable",
      label: "Regular Burdenable?",
      type: "boolean"
    }, {
      name: "ot_burdenable",
      label: "Overtime Burdenable?",
      type: "boolean"
    }, {
      name: "dt_burdenable",
      label: "Double time Burdenable?",
      type: "boolean"
    }, {
      name: "ecs1_codes",
      label: "Earn Code Set 1 Codes",
      type: "long-text"
    }, {
      name: "ecs1_burdenable",
      label: "Earn Code Set 1 Burdenable?",
      type: "boolean"
    }, {
      name: "ecs2_codes",
      label: "Earn Code Set 2 Codes",
      type: "long-text"
    }, {
      name: "ecs2_burdenable",
      label: "Earn Code Set 2 Burdenable?",
      type: "boolean"
    }, {
      name: "ecs3_codes",
      label: "Earn Code Set 3 Codes",
      type: "long-text"
    }, {
      name: "ecs3_burdenable",
      label: "Earn Code Set 3 Burdenable?",
      type: "boolean"
    }, {
      name: "other_burdenable",
      label: "Other Earn Codes Burdenable?",
      type: "boolean"
    }],
  bullhornrest_BillableCharge: [
    {
      name: "ecs1_codes",
      label: "Earn Code Set 1 Codes",
      type: "long-text"
    }, {
      name: "ecs2_codes",
      label: "Earn Code Set 2 Codes",
      type: "long-text"
    }, {
      name: "ecs3_codes",
      label: "Earn Code Set 3 Codes",
      type: "long-text"
    }]
});

const crmTypeToTopLevelProperties = Immutable.fromJS({
  talentrover: [
    {
      name: "legacyContactMeetingCallBehaviour",
      label: "Legacy Meeting / Call behaviour",
      type: "boolean"
    }],
  bullhornrest: [
    {
      name: "directLabourCostEntity",
      label: "Custom object for direct labour costs",
      type: "string"
    }]
});

const commonTopLevelProperties = Immutable.fromJS([
  {
    section: "Placement",
    name: "rotationCalculationType",
    label: "Rotations",
    type: "enum",
    options: [
      {
        label: "Calculate actual days (Recommended)",
        value: "DAYS"
      }, {
        label: "Estimate with ratio",
        value: "RATIO"
      }]
  }, {
    name: "crmInstanceSupports",
    label: "CRM supports",
    type: "enum",
    multi: true,
    options: crmInstanceSupportOptions
  }, {
    section: "Placement",
    name: "contractPlacementValueCalculation",
    label: "Contract value calculation",
    type: "enum",
    options: [
      {label: "Daily GP and Duration", value: "daily_gp_and_duration"},
      {label: "Standard", value: "STANDARD"}]
  }, {
    section: "Placement",
    name: "scalingFactor",
    label: "Scaling factor",
    type: "float"
  }, {
    section: "Placement",
    name: "reportedMargin",
    label: "Reported margin",
    type: "float"
  }, {
    section: "Placement",
    name: "contractWorkingDaysCap",
    label: "Contract working days cap",
    type: "integer"
  }, {
    name: "excludeDataForUserCrmIds",
    label: "Exclude data for user CRM IDs",
    type: "json-data",
    jsonShape: "flat-array"
  }, {
    name: "excludeClientsAddedByUserIds",
    label: "Exclude clients added by user CRM IDs",
    type: "json-data",
    jsonShape: "flat-array"
  }, {
    section: "Placement",
    name: "burdenRate",
    label: "Burden rate",
    type: "float"
  }, {
    section: "Placement",
    name: "placementChainByPrevious",
    label: "Chain by previous",
    type: "boolean"
  }, {
    section: "Placement",
    name: "allowNegativePlacementValues",
    label: "Allow negative values",
    type: "boolean"
  }, {
    section: "Placement",
    name: "usePlacementStartDateAsEffectiveDate",
    label: "Start Date for Effective Date",
    type: "boolean"
  }, {
    section: "Placement",
    name: "sendDealFlashForInvisiblePlacements",
    label: "Deal flash invisible placements",
    type: "boolean"
  }, {
    section: "Placement",
    name: "fixCreatedDateUsingStartDate",
    label: "Fix create date using start date",
    type: "boolean"
  }, {
    section: "Placement",
    name: "shortPlacementsOnWeekends",
    label: "Short placements on weekends",
    type: "boolean"
  }, {
    section: "Placement",
    name: "doNotClampHoursPerDay",
    label: "Do not clamp hoursPerDay",
    type: "boolean"
  }, {
    section: "Placement",
    name: "doNotClampDaysPerWeek",
    label: "Do not clamp daysPerWeek",
    type: "boolean"
  }, {
    name: "allowZeroJobValue",
    label: "Allow zero job value",
    type: "boolean"
  }, {
    name: "allowBlankJobCloseDate",
    label: "Allow blank job close date",
    type: "boolean"
  }, {
    section: "Pay & Bill",
    name: "regEarnCodes",
    label: "REG earn codes",
    type: "string"
  }, {
    section: "Pay & Bill",
    name: "otEarnCodes",
    label: "OT earn codes",
    type: "string"
  }, {
    section: "Pay & Bill",
    name: "dtEarnCodes",
    label: "DT earn codes",
    type: "string"
  }, {
    section: "Interview",
    name: "useInterviewDateAsEffectiveDate",
    label: "Interview Date for Effective Date (DEPRECATED: use KPI overrides instead)",
    type: "boolean"
  }, {
    section: "Meeting",
    name: "useMeetingDateAsEffectiveDate",
    label: "Meeting Date for Effective Date (DEPRECATED: use KPI overrides instead)",
    type: "boolean"
  }]);

export {
  integerTypeAttributes,
  floatTypeAttributes,
  typeOptions,
  takeOptions,
  typeNameToType,
  fieldMappingAttributes,
  commonEntityAttributes,
  crmInstanceSupportOptions,
  commonTopLevelProperties,
  crmTypeToTopLevelProperties,
  commonEntityProps,
  crmTypeToEntityProps,
  crmTypeAndEntityNameToProps
};
